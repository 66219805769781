import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
//import { StaticImage } from "gatsby-plugin-image";
import {
  mainContent,
  description,
  row,
  //col6,
  col12,
  marginTopSubstitute,
  introSection,
  buttonGroup,
} from "./subpage.module.scss";
//import { Link } from "gatsby";

const FeedbackgeberPage = () => {
  return (
    <Layout pageTitle="Leistungsanforderung" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
          <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                <p className={description}>Artikel - 13. Februar 2023</p>
                <h1>Digitaler Fortschritt braucht die richtigen Partner</h1>

                <p className="text-medium">
                  Interview mit Peter Summermatter in IT-Branchenreport 1/2023
                </p>

                <h3 className={marginTopSubstitute}>
                  Feedbackgeber der Entscheiderfabrik{" "}
                </h3>
                <p>
                  Damit Healthcare-Einrichtungen auch bei der anhaltenden
                  herausfordernden Lage nicht den Weg durch die digitale
                  Transformation aus den Augen verlieren, kommt es auf
                  verlässliche Kooperationen an.{" "}
                </p>

                <p>
                  Health-IT-Experte Summermatter erklärt, wie diese gelingen
                  können.{" "}
                </p>

                <div className={buttonGroup}>
                  <a
                    className="button-outline blue"
                    href="https://www.tie.ch/downloads/IT-BranchenReport-2023.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Zum Artikel
                  </a>
                </div>
              </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default FeedbackgeberPage;
